html{
  scroll-snap-type: y mandatory;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #050505;
  color: white;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
iframe{
  border: none !important;
}


::-webkit-scrollbar {
  width: 0.3rem;
}


::-webkit-scrollbar-track {
  background: #8d8d8d;
}


::-webkit-scrollbar-thumb {
  background: rgb(61, 61, 61);
}


::-webkit-scrollbar-thumb:hover {
  background: #555;
}
