.blueShadow{
  box-shadow:0 5px 30px 0 rgba(2, 4, 32, 0.247) !important;
}
.float-scroll{
  width: 100%;
  position: fixed;
  bottom: 0;
  margin: 0px auto;
  /* left: 47%; */
  /* margin-bottom: 5px; */
}
button{
  display: flex;
  align-items: center;
  border: none;
  color: red;
  padding: 15px;
  color: white;
  background: #0F3E60;
  border-radius: 35px;
}
.btn-recrutamento{
  font-size: 1.2rem;
  padding: 20px;
  transition: transform 0.1s ease;
  background: #8A2387;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #0F3E60, #176daa);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #0F3E60, #176daa); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.btn-recrutamento:hover{
  transform: scale(1.1);
  transition: transform 0.1s ease;
}
@media(max-width: 552px){
  .header-actions .btn-recrutamento{
    height: 30px;
    font-size: 0.9rem!important;
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 70px;
  /* pointer-events: none; */
}

.underline{
  text-decoration: underline solid #0F3E60;
}

.header {
  background-color: rgba(13, 13, 13, .2);
  backdrop-filter: blur(4px);
  position: fixed;
  height: 10vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* font-size: calc(10px + 2vmin); */
  color: #84afce;
  padding: 15px 0px 15px 0px;
  /* box-shadow:0 5px 30px 0 rgba(0,0,0,0.05) !important; */
}
.header-small{
  background-color: rgba(13, 13, 13, .2);
  backdrop-filter: blur(4px);
  position: fixed;
  height: 5vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* font-size: calc(10px + 2vmin); */
  color: #84afce;
  padding: 15px 0px 15px 0px;
}
.header-actions{
  display: flex;
}
section{
  min-height: 90vh;
  scroll-snap-align: start;
  padding: 2rem;
}
.header .react-icons {
  color: red;
}
lottie-player{
  height: 70px;
}
.social-icons-ul{
  list-style: none;
  display: flex;
}
.social-icons-ul li {
  margin-right: 15px;
}
.social-icons{
  color: white;
}
.jumbotron{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: white;
  height: 100vh;
  text-align: center;
  background-image: linear-gradient(rgba(13, 13, 13, .9), rgba(13, 13, 13, .55)), url('./imgs/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.jumbotron h1{
  font-size: 7em;
  color: transparent;
  letter-spacing: 20px;
  font-weight: bold;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color:white;
}
@media (max-width: 1094px){
  .jumbotron h1{
    font-size: 2em;
    letter-spacing: 10px;
    -webkit-text-stroke-width: 1px;
  }
  .jumbotron h2{
    font-size: 1.5em !important;
  }
}
.jumbotron h2{
  font-size: 2.8em;
  color: white;
  font-weight: 300;
}
.about{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.about h2{
  font-weight: 500;
  font-size: 2em;
}
.about h3{
  font-weight: 100;
  font-size: 1.1em;
}
.about p{
  font-size: 0.9em;
  color: #e6e6e6;
  line-height: 1.8;
}
.benefit-section{
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.benefits-grid{
  display: grid;
  width: 90vw;
  grid-gap: 15px;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(140px,1fr));
}
.discord{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.discord h2{
  font-size: 1.9rem;
}
.discord-print{
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  align-self: right;
}
.discord-img{
  border-radius: 15px;
  height: 450px;
}
@media (max-width: 760px){
  .discord-print{
    justify-content: center;
  }
  .discord-img{
    height: 200px;
  }
}
.discord-description{
  width: 250px;
  text-align: left;
  padding: 10px;
  flex-grow: 2;
}
.streamers{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.streamers-grid{
  display: grid;
  grid-auto-flow: row;
  width: 70vw;
  grid-gap: 25px;
  justify-items: center;
  /* grid-template-columns: repeat(3, 1fr); */
  grid-template-columns: repeat(auto-fit, minmax(250px,1fr));
}
@media (max-width: 1262px){
  .streamers-grid{
    grid-template-columns: repeat(auto-fill, minmax(280px,1fr));
  }
}
.recruitment{
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@media (max-width: 900px){
  .contact{
    flex-direction: column;
  }
}

