.streamercard{
    text-align: left;
    width: 250px;
    /* padding: 25px; */
    background-color: black;
    justify-self: center;
    border-radius: 10px;
    /* box-shadow:0 5px 30px 0 rgba(2, 4, 32, 0.247) !important; */
}
@media (max-width: 1262px){
    .streamercard{
        display: flex;
        width: 280px;
    }
    .streamercard h1{
        font-size: 1rem !important;
    }
    .cardheader{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
a{
    text-decoration: none;
    font-size: 0.8rem;
    color: white;
}
.streamerThumb{
    width: 100%;
    height: auto;
}
.facebook{
    border-bottom: 2px solid #1877F2;
}
.twitch{
    border-bottom: 2px solid red;
}
.cardheader{
    padding: 15px;
}
.streamercard h1{
    font-size: 1.3rem;
    margin: 0;
}
.benefitcard p{
    font-size: 1rem;
    line-height: 1.3;
    color: rgb(201, 201, 201); 
}