.benefitcard{
    /* width: 150px; */
    /* height: 150px; */
    /* border: 2px solid red; */
    padding: 25px;
    background-color: black;
    justify-self: center;
    border-radius: 10px;
    /* box-shadow:0 5px 30px 0 rgba(2, 4, 32, 0.445) !important; */
    /* box-shadow: 0px 5px 30px 0 rgba(2, 4, 40, 1); */
}
.benefitcard h2{
    font-size: 1.3rem;
}
.benefitcard p{
    font-size: 0.9rem;
    font-weight: 300;
    line-height: 1.3;
    color: rgb(201, 201, 201); 
}